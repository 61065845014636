<template>
  <div id="app" class="app-container">


    <!--Desktop-->
    <nav v-if="!mobile" class="navbar navbar-expand">
      <router-link to="/" class="navbar-brand">
        <img src="./Images/Logo.png" width="250px" />
      </router-link>

      <div class="navbar-container">
        <!-- not Authenticated-->
        <div v-if="!isAuthenticated" class="navbar-nav">

          <li class="nav-item"><a class="nav-link" href @click.prevent="login"><font-awesome-icon
                icon="sign-in-alt" />LogIn</a></li>


        </div>

        <!--Authenticated-->
        <div v-if="isAuthenticated" class="navbar-nav">
          <li v-if="admin" class="nav-item"><router-link to="/verwalten/schulen" class="nav-link">Verwalten</router-link>
          </li>
          <li v-if="admin" class="nav-item"><router-link to="/befunde" class="nav-link">Befunde</router-link></li>
          <li v-if="doc" class="nav-item"><router-link to="/addBefund" class="nav-link">Befund</router-link></li>
          <li class="nav-item"><router-link to="/profile" class="nav-link">{{ currentUser }}</router-link></li>
          <li class="nav-item"><a class="nav-link" href @click.prevent="logout"><font-awesome-icon
                icon="sign-out-alt" />LogOut</a></li>
        </div>
      </div>
    </nav>

    <!--Mobile-->
    <nav v-if="mobile" class="navbar navbar-mobile">
      <router-link to="/" class="navbar-brand">
        <img class="img-mobile" src="./Images/Logo-Mobile.png" width="70px" />
      </router-link>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobile-nav-dropdown"
        aria-controls="mobile-nav-dropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="mobile-nav-dropdown">
        <!-- not Authenticated-->
        <div v-if="!isAuthenticated" class="collapse-container">
          <ul class="navbar-nav-mobile">
            <li class="nav-item-mobile"><a class="nav-link-mobile" href @click.prevent="login">Login</a></li>
          </ul>
        </div>
        <!-- Authenticated-->
        <div v-if="isAuthenticated" class="collapse-container">
          <ul class="navbar-nav-mobile">
            <li v-if="admin" class="nav-item-mobile"><router-link to="/verwalten/schulen" class="nav-link-mobile"
                @click.native="closeDropdown">Verwalten</router-link></li>
            <li v-if="admin" class="nav-item-mobile"><router-link to="/befunde" class="nav-link-mobile"
                @click.native="closeDropdown">Befunde</router-link></li>
            <li v-if="doc" class="nav-item-mobile"><router-link to="/addBefund" class="nav-link-mobile"
                @click.native="closeDropdown">Befund</router-link></li>
            <li class="nav-item-mobile"><router-link to="/profile" class="nav-link-mobile"
                @click.native="closeDropdown">{{ currentUser }}</router-link></li>
            <li class="nav-item-mobile"><a class="nav-link-mobile" href @click.prevent="logout">LogOut</a></li>
          </ul>
        </div>
      </div>
    </nav>


    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import Vue from "vue";


export default {
  name: "app",
  data() {
    return {
      mobile: false,
      admin: false,
      doc: false,
    }
  },

  //Checking for roles
  created() {
    if (this.isAuthenticated) {
      const roles = Vue.$keycloak.resourceAccess.zahnGesundheitsCheck.roles;
      if (roles.includes("admin")) {
        this.admin = true;
      }
      if (roles.includes("arzt")) {
        this.doc = true;
      }
    }
  },

  mounted() {
    // update mobile when the component is mounted
    this.checkMobile();
    // add event listener to update mobile on window resize
    window.addEventListener("resize", this.checkMobile);

  },
  destroyed() {
    // remove event listener when the component is destroyed
    window.removeEventListener("resize", this.checkMobile);
  },
  computed: {
    isAuthenticated: () => {
      return Vue.$keycloak.authenticated;
    },
    currentUser() {
      return (
        Vue.$keycloak.idTokenParsed.given_name +
        " " +
        Vue.$keycloak.idTokenParsed.family_name
      );
    },
  },

  methods: {
    login() {
      Vue.$keycloak.login({ redirectUri: window.location.origin });
    },
    logout() {
      Vue.$keycloak.logout({ redirectUri: window.location.origin });
    },
    //User Agent --> gives information about the operating system of the device // and More Information about webbrowser etc. 
    checkMobile() {
      const userAgent = navigator.userAgent.toLowerCase();
      const mobileKeywords = ['iphone', 'ipod', 'ipad', 'android', 'webos', 'blackberry', 'iemobile', 'opera mini'];
      const isMobile = mobileKeywords.some(keyword => userAgent.includes(keyword)) || window.innerWidth <= 1150;
      this.mobile = isMobile;
    },
    closeDropdown() {
      const dropdown = document.querySelector('#mobile-nav-dropdown');
      dropdown.classList.remove('show');
    },

  },
};
</script>

<style scoped>

html, body {
  background-color: #a6d8be;
}


.app-container {
  width: 100%;
  height: 100vh;
 
  background-size: cover;
  background-repeat: no-repeat;
}

.navbar {
  background-color: #eaeaea;
  display: flex;
  flex-direction: row;
  transition: .5s ease all;
  height: 120px;
}

.navbar-brand {
  margin-left: 2em;
}

.navbar-container {
  margin-left: auto;
  /* Setzt den Abstand zum rechten Rand der Navigationsleiste auf automatisch, so dass die Container-Div den restlichen Platz einnimmt */
  margin-right: 2em;
  display: flex;
  align-items: center;
}

.nav-item {
  text-decoration: none;
  font-size: 20px;
  margin-right: 1em;
  flex-shrink: 0;

}

.nav-link {
  color: black;
}

.nav-link:hover {
  color: #41a58d;
  transition: .5s ease all;
}

/* Mobile Version */
@media screen and (max-width: 1700px) {
  body,
    html {
        /* zoom: 85%; */

        height: 100%;
    }

  .app-container{
    height: 100%;
  }

}

@media screen and (max-width: 960px) {
  .app-container {
    width: 100%;
    height: 100%;
    background: #a6d8be;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .navbar {
    background-color: #eaeaea;
    transition: .5s ease all;
    display: flex;
    height: 100px;
    width: 100%;

  }

  .navbar-nav-mobile {
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;

  }

}

.navbar-mobile .navbar-toggler {

  margin-left: auto;
}

.img-mobile {
  margin-left: -1em;
}

.navbar-mobile .navbar-toggler:focus,
.navbar-mobile .navbar-toggler:hover {
  color: #3c896e;

}

.navbar-mobile .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-mobile .navbar-nav-mobile {
  display: flex;
  flex-direction: column;

  margin: auto;
  margin-top: 1em;
  width: 90%;
  text-align: center;
  border-top: 5px solid #3c896e;

}

.navbar-mobile .nav-item-mobile {
  display: block;
  margin-top: 2em;
  text-align: center;
  width: 100%;
  /* Breite auf 100% setzen */
}

.navbar-mobile .nav-link-mobile {
  font-size: 25px;
  color: black;
  text-decoration: none;
  transition: 0.5s ease all;
}

.navbar-mobile .nav-link-mobile:hover {
  color: #41a58d;
  transition: 0.5s ease all;
}



.collapse {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 80vh;
  background: #ececec;
  z-index: 1;
  opacity: 0.9;
}

.collapse-container {
  margin: auto;
}

.navbar-nav-mobile {
  height: 70vh;

}</style>
