import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("./components/AllgemeineSeiten/Home"),
    meta: {
      isAuthenticated: false
    }
  },
  {
    path: "/verwalten",
    name: "verwalten-ga",
    component: () => import("./components/Gesundheitsamt/Manage-ga"),
    meta: {
      isAuthenticated: false
    }
  },
  {
    path: "/verwalten/schulen",
    name: "verwalten-schulen",
    component: () => import("./components/Gesundheitsamt/ManageSchool-ga"),
    meta: {
      isAuthenticated: false
    },
  },
  {
    path: "/verwalten/schulen-hinzufügen",
    name: "schulen-hinzufügen-ga",
    component: () => import("./components/Gesundheitsamt/AddSchool-ga"),
    meta: {
      isAuthenticated: false
    },
  },

  {
    path: "/verwalten/Arzt",
    name: "verwalten-aertze",
    component: () => import("./components/Gesundheitsamt/ManageUser-ga.vue"),
    meta: {
      isAuthenticated: false
  
    },
  },

  {
    path: "/verwalten/Arzt-hinzufügen",
    name: "verwalten-aertze",
    component: () => import("./components/Gesundheitsamt/AddUser-ga.vue"),
    meta: {
      isAuthenticated: false
    },
  },

  {
    path: "/befunde",
    name: "befunde-ga",
    component: () => import("./components/Gesundheitsamt/Findings-ga.vue"),
    meta: {
      isAuthenticated: true
    },

  },

  {
    path: "/addBefund",
    name: "findings-doc",
    component: () => import("./components/Arzt/Findings-doc.vue"),
    meta: {
      isAuthenticated: true
    }
  },

  {
    path: "/profile",
    name: "manage-account",
    component: () => import("./components/Gesundheitsamt/Account-ga.vue"),
    meta: {
      isAuthenticated: true
    }
  }

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Vue.mixin({
  beforeCreate() {
    if (this.$keycloak) {
      this.$keycloak.onAuthSuccess = () => {
        const redirectPath = Vue.$cookies.get('redirectPath');
        if (redirectPath) {
          this.$router.push(redirectPath);
          Vue.$cookies.remove('redirectPath');
        }
      };
    }
  }
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.isAuthenticated) {
    if (!Vue.$keycloak.authenticated) {
      // Der Benutzer ist nicht authentifiziert. Führe die Anmeldung durch und speichere den ursprünglichen Pfad in den Browser-Cookies.
      Vue.$keycloak.login();
    } else if (Vue.$keycloak.hasResourceRole('admin') || Vue.$keycloak.hasResourceRole('arzt')) {
      // Der Benutzer ist authentifiziert und hat die erforderliche Rolle. Aktualisiere das Token und setze den Pfad fort.
      try {
        await Vue.$keycloak.updateToken(70);
        next();
      } catch (error) {
        console.error('Fehler beim Aktualisieren des Tokens:', error);
        next('/'); // Fallback: Weiterleitung zur Startseite
      }
    } else {
      // Der Benutzer ist authentifiziert, hat jedoch nicht die erforderliche Rolle. Leite auf eine Fehlerseite weiter.
      // next({ name: 'arzt' });
    }
  } else {
    // Diese Seite erfordert keine Authentifizierung. Setze den Pfad fort.
    next();
  }
});

export default router

